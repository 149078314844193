import { startOfYear, setYear, startOfDay, endOfDay } from 'date-fns';

export function past50Years () {
  const currentYear = new Date().getFullYear();
  const years = [];
  for (let i = currentYear; i >= currentYear - 50; i--) {
    years.push(i);
  }
  return years;
}

export function getUnixTimestampAtStartOfYear (yearString) {
  const year = parseInt(yearString);
  const timestamp = startOfYear(setYear(Date.now(), year));
  const unixTimestamp = Math.floor(timestamp);
  return unixTimestamp;
}

export function getUnixTimestampAtStartOfDay (dateString) {
  if (typeof dateString !== 'number') dateString = parseInt(dateString);
  const timestamp = startOfDay(dateString);
  const unixTimestamp = Math.floor(timestamp);
  return unixTimestamp;
}

export function getUnixTimestampAtEndOfDay (dateString) {
  if (typeof dateString !== 'number') dateString = parseInt(dateString);
  const timestamp = endOfDay(dateString);
  const unixTimestamp = Math.ceil(timestamp);
  return unixTimestamp;
}
